//burger menu
window.addEventListener("load", () => {
  var menuBtn = document.querySelector(".burger-menu--btn");
  var menu = document.querySelector(".navigation");
  menuBtn.addEventListener("click", () => {
    if (menuBtn.classList.contains("burger-menu--btn-active")) {
      menuBtn.classList.remove("burger-menu--btn-active");
      menu.classList.remove("menu-show");
    } else {
      menuBtn.classList.add("burger-menu--btn-active");
      menu.classList.add("menu-show");
    }
  });
});

//form

window.addEventListener("load", () => {
  if (document.querySelector("#send-message")) {
    document.querySelector("#send-message").addEventListener("submit", () => {
      var message = document.querySelector(".message");
      var messageTxt = document.querySelector(".messageTxt");
      var loader = document.querySelector(".loader");
      message.style.display = "flex";
      loader.classList.remove("hide");
      setTimeout(hideLoaderAndShowMessageTxt, 1000);
      setTimeout(hideMessageTxt, 2500);

      function hideLoaderAndShowMessageTxt() {
        loader.classList.add("hide");
        messageTxt.classList.remove("hide");
      }
      function hideMessageTxt() {
        document.querySelector("#nameInput").value = "";
        document.querySelector("#mailInput").value = "";
        document.querySelector("#messageInput").value = "";
        messageTxt.classList.add("hide");
        message.style.display = "none";
      }
    });
  }
});
